import "./Home.css"
import React from "react"
import { Language } from "../Utils/language"

export default function Home() {
    
    return (
        <main className="home">
            <div className="home-title-div">
                <h1 className="">{Language("Olá Mundo!", "Hello World!")}</h1>
                <span>{Language("Bem vindos ao meu portfólio!", "Welcome to my portfolio!")}</span>
            </div>
        </main>
    )
}