import "./Projects.css"
import React, { useState } from "react"

export default function Projects() {

    const [selectedProject, setSelectedProject] = useState({})
    const [visible, setVisible] = useState(false)

    const projects = [
        {
            image: "quizapp.png",
            title: "QuizApp",
            link: "https://quizapp-game.netlify.app"
        },
        {
            image: "covidtest.png",
            title: "Teste de Covid",
            link: "https://hospital-local.netlify.app"
        }
    ]

    function handleVisible() {
        visible === true ? setVisible(false) : setVisible(true)
    }

    return (
        <>
            {
                visible &&
                <div className="project-view">
                    <div className="project-frame">
                        <div className="project-frame-header">
                            <span>{selectedProject.title}</span>
                            <button onClick={handleVisible}>𝘅</button>
                        </div>
                        <iframe src={selectedProject.link} title="W3Schools Free Online Web Tutorials"></iframe>
                    </div>
                </div>
            }
            <main className="projects">
                {
                    projects.map((project) => {
                        return (
                            <div onClick={() => {
                                setSelectedProject(project)
                                handleVisible()
                            }} key={project.link} className="project">
                                <div style={{ backgroundImage: `url(/images/projects/${project.image})` }} className="project-image"></div>
                                <div className="project-title">{project.title}</div>
                            </div>
                        )
                    })
                }
            </main>
        </>
    )
}