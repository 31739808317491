import React, {useState, useEffect} from "react"
import About from "../Pages/About"
import Home from "../Pages/Home"
import Projects from "../Pages/Projects"
import Contact from "../Pages/Contact"

export function selectLanguage() {
    const data = localStorage.getItem("lang")
    const lang = data ? data : "pt"
    return lang
}

export function changeLanguage(lang) {
    localStorage.setItem("lang", lang)
}

export function Language(pt, en) {
    const [language, setLanguage] = useState("")

    useEffect(() => {
        setLanguage(selectLanguage())
    }, [])

    return (language === "pt" ? pt : en)
}

export const languages = [
    {
        id: "pt",
        language1: "Português",
        language2: "Portuguese",
        pages: [
            {
                path: "/",
                name: "Home",
                element: <Home/>,
            },
            {
                path: "/projetos",
                name: "Projetos",
                element: <Projects/>
            },
            {
                path: "/sobre",
                name: "Sobre",
                element: <About/>
            },
            {
                path: "/contato",
                name: "Contato",
                element: <Contact/>
            }
        ]
    },
    {
        id: "en",
        language1: "Inglês",
        language2: "English",
        pages: [
            {
                path: "/",
                name: "Home",
                element: <Home/>
            },
            {
                path: "/projects",
                name: "Projects",
                element: <Projects/>
            },
            {
                path: "/about",
                name: "About",
                element: <About/>
            },
            {
                path: "/contact",
                name: "Contact",
                element: <Contact/>
            }
        ]
    }
]