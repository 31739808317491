import './index.css';
import { Route, BrowserRouter as Router, Link, Routes } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { selectLanguage, languages } from './Utils/language';

function App() {
    const [language, setLanguage] = useState("")
    const [dataLang, setDataLang] = useState("")

    useEffect(() => {
        setLanguage(selectLanguage())
    }, [])

    useEffect(() => {
        const lang = languages.find((lang) => lang.id === language)
        setDataLang(lang)
    }, [language])

    return (
        (language && dataLang) &&
        <Router>
            <header className="App-header">
                {/* <div className='div-first'>
                    <select name="language" id="language" onChange={(element) => { changeLanguage(element.target.value); window.location.reload(); window.location.href = "/" }}>
                        {
                            languages.map((lang) => {
                                return (
                                    <option key={lang.id} selected={language === lang.id ? true : false} value={lang.id}>{languages[languages.indexOf(lang)][language === "pt" ? "language1" : "language2"]}</option>
                                )
                            })
                        }
                    </select>
                </div> */}
                <div className='div-last'>
                    {dataLang.pages.map((page) => {
                        return (
                            <Link key={page.id} to={page.path}><button onClick={(element) => {
                                document.querySelectorAll(".div-last-button").forEach((button) => {
                                    button.classList.remove("selected-button")
                                })
                                element.target.classList.add("selected-button")
                            }} className={window.location.pathname === page.path ? "div-last-button selected-button" : "div-last-button"} >{page.name}</button></Link>
                        )
                    })}
                </div>
            </header>
            <Routes>
                {dataLang.pages.map((page) => {
                    return (
                        <Route key={page.path} path={page.path} element={page.element}></Route>
                    )
                })}
            </Routes>
        </Router>
    );
}

export default App;
