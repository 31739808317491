import "./About.css"
import React from "react"

export default function About() {

    const skills = [
        { name: "C#", value: 4 },
        { name: "PHP", value: 6 },
        { name: "JavaScript", value: 10 },
        { name: "HTML", value: 8 },
        { name: "CSS", value: 8 },
        { name: "React", value: 9 },
        { name: "Java", value: 3 },
        { name: "TypeScript", value: 7 },
        { name: "AWS (Amazon Web Services)", value: 7 },
        { name: "Microsoft Azure Cloud", value: 6 },
        { name: "XML", value: 10 },
        { name: "ActionScript", value: 7 },
        { name: "Lua", value: 6 },
        { name: "Batch", value: 5 },
        { name: "Shell", value: 4 },
        { name: "Python", value: 3 },
        { name: "git", value: 7 },
        { name: "node", value: 8 },

    ].sort((a, b) => { return b.value - a.value })

    return (
        <main className="about">
            <div className="about-left">
                <div className="profile">
                    <div className="profile-header">
                        <div className="profile-background">
                            <div className="profile-pic">
                            </div>
                        </div>
                    </div>
                    <div className="profile-body">
                        <h3>❲ DADOS PESSOAIS ❳</h3>
                        <span className="profile-name"><span>Nome</span><span>Geronimo Neto</span></span>
                        <span className="profile-name"><span>Idade</span><span>20</span></span>
                        <br />
                        <h3>❲ HABILIDADES TÉCNICAS ❳</h3>
                        {
                            skills.map((skill) => {
                                let value = ""
                                for (let i = 0; i < skill.value; i++) {
                                    value += "★"
                                }
                                let stars = ""
                                for (let j = skill.value; j < 10; j++) {
                                    stars += "☆"
                                }
                                return (
                                    <span key={skill.name} className="profile-name">
                                        <span>{skill.name}</span>
                                        <span style={{ fontFamily: "Arial" }} title={skill.value + "/" + 10}>{value + stars}</span>
                                    </span>
                                )
                            })
                        }
                    </div><br />
                </div>
            </div>
            {/* <div className="about-right">
                <div className="skills">

                </div>
            </div> */}
        </main>
    )
}